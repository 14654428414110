<template>
  <v-expand-transition>
    <v-card
      v-show="showComment"
      ref="comment"
    >
      <v-card-text>
        <v-textarea
          :label="label || $t('t.Comments')"
          v-model="value"
          :rows="rows"
          :readonly="disabled"
          :counter="counter"
          :rules="rules"
          no-resize
        />
      </v-card-text>
    </v-card>
  </v-expand-transition>
</template>

<script>

export default {
  computed: {
    value: {
      get () {
        return this.text
      },
      set (val) {
        this.$emit('update:text', val)
      }
    }
  },
  watch: {
    showComment: function (value) {
      if (value) {
        this.$nextTick(() => this.$refs.comment.$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' }))
      }
    }
  },
  props: {
    label: String,
    text: String,
    disabled: Boolean,
    showComment: Boolean,
    rows: {
      type: Number,
      default: 3
    },
    counter: Number,
    rules: Array
  }
}
</script>
